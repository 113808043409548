import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoleUIService } from 'src/app/shared/role-manager/role-ui.service';
import { AdminRecord, GuestRecord, ParentChildRecord, RoleRecord, StudentRecord, TeacherRecord } from '../../data';
import { LocalStorageService } from '../../platform-web3/local-storage.service';
import { PlatformUrlService } from '../../platform-web3/platform-url.service';
import { UserRoleState } from '../../platform-web3/user-role.state';
import { Role } from '../../platform-web3/user.state';
import { RoleFormatPipe } from '../../shared/pipes/role-format.pipe';

type RoleCombine = TeacherRecord & ParentChildRecord & StudentRecord & AdminRecord & GuestRecord;

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.css'],
})
export class AccountMenuComponent implements OnInit, OnDestroy {

  unSubscribe$ = new Subject();
  role: RoleCombine = {} as RoleCombine;
  mapSchoolRoles: Map<string, RoleCombine[]> = new Map();

  constructor(
    private store: Store,
    private nav: PlatformUrlService,
    private router: Router,
    private local: LocalStorageService,
    private roleui: RoleUIService,
    private rolePipe: RoleFormatPipe,
    @Inject(I18NEXT_SERVICE) private i18nextSrv: ITranslationService,
  ) {
    i18nextSrv.events.languageChanged.subscribe((lang) => {
      console.log(lang);
    });
  }

  async ngOnInit() {
    const { store } = this;

    store.select(UserRoleState.selectedRole).pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe(r => this.role = (r ?? {}) as RoleCombine);

    store.select(UserRoleState.getRoles).pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe(ar => {
      const colDSNS: Map<string, RoleCombine[]> = new Map();
      ar.forEach(v => {
        if (!colDSNS.has(v.dsns)) {
          colDSNS.set(v.dsns, [v] as RoleCombine[]);
        } else {
          colDSNS.set(v.dsns, colDSNS.get(v.dsns).concat(v as RoleCombine));
        }
      });
      this.mapSchoolRoles = colDSNS;
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
  }

  isSelected(role: RoleRecord) {
    return (role.dsns === this.role.dsns)
      && (role.roleName === this.role.roleName)
      && (role.id === this.role.id);
  }

  setCurrentIdentity(identity: RoleRecord, role: Role) {
    this.local.setPreference('$SelectedRole', identity);
    this.nav.goSchool(identity.dsns, { dsns: identity.dsns, role, id: identity.id });
  }

  async openJoinModal() {
    this.roleui.openJoinRole();
  }

  manageRole() {
    this.router.navigate(['/personal-setting', `identity`]);
  }

  formatRoleData(item: RoleCombine): string {
    if (item.roleName === 'parent') {
      /**
       * 這寫法是為了處理特殊字問題。
       * 特殊字會有 & 符號，在多語系轉換後會變成「&amp;」，這樣就破壞了 html 結構。
       * 所以先用一個沒問題的字串，轉換後再用取代方式換成真的名字。
       */
      const lang = this.i18nextSrv.t('v1的家長', { v1: 'RoleName' }).toString();
      return lang.replace('RoleName', item.name);
    } else {
      return this.rolePipe.transform(item.roleName) as string;
    }
  }
}
