<!-- Modal - 手機掃描綁定 -->
<div class="text-center">
  <h2 class="mat-dialog-title" style="margin-top: 15px" id="accountQRLabel">{{ '手機掃描綁定' | i18next }}</h2>
  <mat-dialog-content>
    <ng-container *ngIf="!errCodeMsg;else tplCodeError">
      <img [src]="qrCodeURL" class="mb-3">
      <p class="timer">{{ '剩餘 v1 重置' | i18next: { v1: qrDurationFormat } }}</p>
      <button mat-flat-button color="primary" (click)="getQRCode()"
        class="w-100 rounded-pill">{{ '直接重置 QR Code' | i18next }}</button>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ '關閉' | i18next }}</button>
  </mat-dialog-actions>
</div>

<ng-template #tplCodeError>
  <p>{{errCodeMsg}}</p>
</ng-template>
